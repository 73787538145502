export type IFilterMethod = 'q' | 'match' | 'eq' | 'ne' | 'in' | 'not_in' | 'range' | 'exist' | 'not_exist' | 'param';

export interface IAggregation {
  value: string;
  count: number;
}

export interface IFilter {
  attribute: string;
  readonly method: IFilterMethod;
  searchOperator?: string;
}

export type IPresetFilter =
  | ['_', 'q', string]
  | [string, 'match' | 'eq' | 'ne', string | number | boolean]
  | [string, 'in' | 'not_in', Array<string | number> | string | number]
  | [string, 'range', string | number, string | number]
  | [string, 'exist' | 'not_exist'];

export enum ParametronSort {
  DISPLAY_TITLE = 'display_title',
  YEAR_OF_PRODUCTION = 'year_of_production',
  PRODUCTS_YEAR_OF_PRODUCTION = 'default_layer.meta.year_of_production',
  UPDATED_AT = 'updated_at',
  CREATED_AT = 'created_at',
  NAME = 'name',
  PUBLISHED_AT = 'published_at',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  TITLE = 'title',
  LAST_LOGIN = 'last_login_at',
  FLAT_SEQUENCE_NUMBER = 'flat_sequence_number',
  SEQUENCE_NUMBER = 'sequence_number',
  DATE = 'date',
  DATE_FROM = 'date_from',
  DATE_TO = 'date_to',
}

export enum LocationsSort {
  NAME = 'name',
  LOCATION_TYPE = 'meta.location_type',
  STARTS_AT = 'starts_at',
  ENDS_AT = 'ends_at',
  UPDATED_AT = 'updated_at',
}

export enum ParametronOrder {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export interface IPresetParams {
  page: number;
  per: number;
  sort: ParametronSort;
  order: ParametronOrder;
  include_deleted?: boolean;
  include_internal_accounts?: boolean;
}

export interface IPreset {
  '@associations': {
    [s: string]: unknown;
  };
  id: number;
  description: string;
  filters: IPresetFilter[];
  search_params: IPresetParams;
}

export enum FilterActions {
  CLEAR = 'clear',
  LOAD_PRESET = 'load-preset',
}

export enum Model {
  ACCESS_PRIVILEGES = 'um.group',
  AFFILIATION = 'um.affiliation',
  ANALYTICS = 'ac.analytics_data',
  ASSET_DIGITAL_IMAGE = 'am.asset/digital/image',
  ASSET_GROUP_ITEMS = 'am.group/item',
  ASSET_LAYER = 'am.layer',
  ASSET_METADATA = 'am.asset/metadata',
  ASSET_MEDIA_INFO = 'mm3:am.asset3/mediainfo',
  ASSET_SCHEDULED_JOB = 'am.scheduled_job',
  ASSET_STATISTICS_DATA = 'am.statistics_data',
  ASSETS = 'am.asset',
  MM3_ASSETS = 'mm3:am.asset3',
  ATTACHMENT = 'am.attachment',
  BUYER_DOMAINS = 'um.buyer/domain',
  SELLER_DOMAINS = 'um.seller/domain',
  CAST_CREW = 'mm3:pm.cast_crew',
  CATEGORIES = 'pm.layer/motion_picture/standard/category',
  COLLECTIONS = 'mm3:um.list/collection',
  CONFERENCE = 'cc.conference',
  CONTACT_GROUP_SET = 'um.group/set',
  SELECTION = 'um.group/set',
  CONTACT_STATISTICS_DATA = 'um.statistics_data',
  CONTACTS = 'um.user',
  CONTACTS_GROUPS = 'um.group',
  CUSTOM_FIELD = 'um.custom_field',
  DIVISIONS = 'um.division',
  DOWNLOAD = 'am.download',
  DOWNLOAD_BATCH = 'am.download/batch',
  EVENTS = 'um.group/event',
  GROUP_SCHEDULED_JOB = 'um.scheduled_job',
  // THIS IS PREFERRABLE WAY to work with any kind of groups (events, recommendations, selections)
  // Please try to avoid introducing new preset target for that matter
  GROUPS = 'um.group',
  INVITE = 'mm3:cal.invite',
  LANGUAGES = 'um.language',
  LIST_ITEMS = 'mm3:um.list/item',
  LISTS = 'mm3:um.list',
  LOCATIONS = 'mm3:cal.location',
  MEETINGS = 'mm3:cal.meeting',
  NEWS = 'mm3:am.news',
  ORGANIZATIONS = 'um.organization',
  BASKET_GROUP_ITEMS = 'pm.basket',
  BASKETS = 'pm.basket',
  PREVIEW_IMAGE = 'am.preview_image',
  PRODUCT_ANCESTRY_INFO = 'pm.product/ancestry_info',
  PRODUCT_ASSET = 'pm.product/asset',
  PRODUCT_GROUP_ITEMS = 'pm.group/item',
  PRODUCT_LAYERS = 'mm3:pm.layer3',
  PRODUCT_STANDARD_LAYER = 'mm3:pm.layer3/motion_picture/standard',
  PRODUCT_STATISTICS_DATA = 'pm.statistics_data',
  PRODUCTS = 'mm3:pm.product3',
  PRODUCER_PORTAL = 'mm3:producer.report',
  RECOMMENDATIONS = 'mm3:mc.recommendation',
  RECOMMENDATIONS_ASSET = 'mm3:mc.recommendation/asset',
  RECOMMENDATIONS_PRODUCT = 'mm3:mc.recommendation/product',
  RECOMMENDATIONS_DETAILS = 'mc.campaign/recommendation',
  SESSION = 'um.session',
  SHOWCASES = 'mm3:um.list/showcase',
  STORAGE = 'am.storage',
  STORAGE_ASSET_HOST_TASK_PREVIEW_IMAGE_EXTRACTION = 'am.storage/asset_host/task/preview_image_extraction',
  SUBTITLES = 'am.asset/artefact/subtitle',
  WORKFLOW = 'jc.workflow',
  DELIVERY_PACKAGE = 'mm3:deliveries.delivery_package',
  CONNECTION = 'mm3:deliveries.connection',
  ARTIFACT = 'am.asset/artefact',
  AUDIO_TRACKS = 'am.asset/artefact/audio_track',
  ACCESS_GROUPS = 'um.group',
  MOBILE_APP_SYNC = 'um.group/mobile_sync',
  NOTIFICATION_PREFERENCES = 'mm3:sm.notification_preference',
}

export interface IFiltersDefinition {
  [key: string]: IFilter;
}

export class QFilter implements IFilter {
  readonly attribute = '+main';
  readonly method = 'q';
  readonly removeSortParam = true;

  constructor(public value: string, public searchOperator: string = 'OR') {}
}

export class MatchFilter implements IFilter {
  readonly method = 'match';
  readonly attribute = 'autocomplete';
  constructor(public value: string | number | boolean, public removeSortParam: boolean = false) {}
}

export class EqFilter implements IFilter {
  readonly method = 'eq';
  constructor(public attribute: string, public value: string | number | boolean) {}
}

export class NeFilter implements IFilter {
  readonly method = 'ne';
  constructor(public attribute: string, public value: string | number | boolean) {}
}

export class InFilter implements IFilter {
  readonly method = 'in';
  constructor(public attribute: string, public value: string | number | Array<string | number>) {}
}

export class NotInFilter implements IFilter {
  readonly method = 'not_in';
  constructor(public attribute: string, public value: string | number | Array<string | number>) {}
}

export class RangeFilter implements IFilter {
  readonly method = 'range';
  constructor(public attribute: string, public start: number | string, public end: number | string) {}
}

// for this validator "value" means "enabled"
export class ExistFilter implements IFilter {
  readonly method = 'exist';
  constructor(public attribute: string, public value: boolean) {}
}

// for this validator "value" means "enabled"
export class NotExistFilter implements IFilter {
  readonly method = 'not_exist';
  constructor(public attribute: string, public value: boolean) {}
}

export const isQFilter = (filter: IFilter): filter is QFilter => {
  return filter.method === 'q';
};

export const isMatchFilter = (filter: IFilter): filter is MatchFilter => {
  return filter.method === 'match';
};

export const isEqFilter = (filter: IFilter): filter is EqFilter => {
  return filter.method === 'eq';
};

export const isNeFilter = (filter: IFilter): filter is NeFilter => {
  return filter.method === 'ne';
};

export const isInFilter = (filter: IFilter): filter is InFilter => {
  return filter.method === 'in';
};

export const isNotInFilter = (filter: IFilter): filter is NotInFilter => {
  return filter.method === 'not_in';
};

export const isRangeFilter = (filter: IFilter): filter is RangeFilter => {
  return filter.method === 'range';
};

export const isExistFilter = (filter: IFilter): filter is ExistFilter => {
  return filter.method === 'exist';
};

export const isNotExistFilter = (filter: IFilter): filter is NotExistFilter => {
  return filter.method === 'not_exist';
};

export const isNoValueFilter = (filter: IFilter): filter is ExistFilter | NotExistFilter => {
  return isExistFilter(filter) || isNotExistFilter(filter);
};

export const isOneValueFilter = (
  filter: IFilter,
): filter is QFilter | MatchFilter | EqFilter | NeFilter | InFilter | NotInFilter => {
  return (
    isQFilter(filter) ||
    isMatchFilter(filter) ||
    isEqFilter(filter) ||
    isNeFilter(filter) ||
    isInFilter(filter) ||
    isNotInFilter(filter)
  );
};

export const isTwoValuesFilter = (filter: IFilter): filter is RangeFilter => {
  return isRangeFilter(filter);
};
