import {
  IAction,
  IActionName,
  ITitleDividerActionName,
  IUseActionsResponse,
  Pages,
  IUseActionsOptionParam,
} from './types';
import { ISelectionActionName } from 'utils/actions/selection/types';

export function cleanUpDividerActions<D extends IActionName>(actions: IAction<D>[]): IAction<D>[] {
  // if two consecutive actions are both dividers we remove one of them
  // if an action is a divider followed/preceded by a title action
  // (which has its integrated upper divider) we remove the divider
  const result = actions.reduce((acc: IAction<D>[], action: IAction<D>, index: number) => {
    if (
      action.name === 'divider' &&
      (acc.length === 0 ||
        actions[index + 1]?.name === 'divider' ||
        actions[index + 1]?.isTitle ||
        actions[index - 1]?.isTitle)
    ) {
      return acc;
    }

    return [...acc, action];
  }, []);

  // if last item is a divider we remove it
  const lastItemIndex = result.length - 1;
  if (lastItemIndex > -1 && result[lastItemIndex].name === 'divider') {
    return result.slice(0, lastItemIndex);
  }

  return result;
}

type ISelectionActions<D extends IActionName> = IAction<D> | IAction<ISelectionActionName>;
export function removeDividers<D extends IActionName>(actions: ISelectionActions<D>[]): ISelectionActions<D>[] {
  const result = actions.filter((action) => action.name !== 'divider');

  return result;
}

export const getValidSelectionActions = (
  actions: IUseActionsResponse<ISelectionActionName>,
): IUseActionsResponse<ISelectionActionName> => {
  return actions.length === 1 && actions[0].name === ITitleDividerActionName.SELECTION ? [] : actions;
};

export const isInMainPage = <T>(options: IUseActionsOptionParam<T>): boolean => {
  return Boolean(options?.page !== undefined && [Pages.LIST, Pages.DETAILS].includes(options.page));
};

export const getClassificationFromType = (type: string): string => {
  if (type.includes('image')) {
    return 'image';
  } else if (type.includes('video')) {
    return 'video';
  } else if (type.includes('audio')) {
    return 'audio';
  } else if (type.includes('document')) {
    return 'document';
  }

  return type;
};
