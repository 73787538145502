import {
  McGenericRecommendationAsset,
  McGenericRecommendationProduct,
  UmGenericListCollection,
  DeliveriesGenericDeliveryPackage,
  DeliveriesGenericConnection,
} from '@mediafellows/mm3-types';
import FullCalendar from '@fullcalendar/react';
import { IconName } from 'blueprint5-icons';

import {
  ItemId,
  DetailsPageTabs,
  IContact,
  IProduct,
  IWebsitePageValues,
  IAsset,
  IConference,
  IMm3Asset,
  IAudioTrack,
} from 'types';
import { IContactActionName } from 'utils/actions/contact/types';
import { IAssetActionName } from 'utils/actions/asset/types';
import { IProductActionName } from 'utils/actions/product/types';
import { ISelectionActionName } from 'utils/actions/selection/types';
import { IOrganizationActionName } from 'utils/actions/organization/types';
import { IShowcaseActionName } from 'utils/actions/showcase/types';
import { ILocationsActionName } from 'utils/actions/location/types';
import { IMarketingEventActionName } from './event';
import { IBasketActionName } from 'utils/actions/basket/types';
import { IAccessPrivilegeActionName } from 'utils/actions/access-privilege';
import { IConferenceActionName } from 'utils/actions/conference/types';
import { ICommonActionName } from 'utils/actions/common';
import { IGroupActionName } from 'utils/actions/common/types';
import { IMobileAppSyncActionName } from 'utils/actions/mobile-app-sync';
import { ISubtitleActionName } from 'utils/actions/subtitle/types';
import type { IRecommendationActionName } from 'utils/actions/recommendation';
import type { IDeliveryActionName } from 'utils/actions/delivery';
import { IMeetingActionName } from 'utils/actions/meeting/types';
import { Intent } from 'utils/ui';

import { IWorkflowActionName } from './workflow/types';
import { ICastCrewActionName } from './cast-crew/types';
import { IListItemActionName } from './list-item/types';
import { ISelection, ISubtitle } from 'types';
import { INewsActionName } from './news/types';
import { IScreeningRoomActionName } from './screening-room/types';
import { IProducerPortalActionName } from './producer-portal/types';
import { IConnectionActionName } from './connection/types';
import { IItemId } from 'components/form-selection-items';
import { IAudioActionName } from './audio/types';
import { IAccessGroupActionName } from './access-group';

export enum ITitleDividerActionName {
  SELECTION = 'Selection',
  PRODUCT = 'Products',
  ASSET = 'Assets',
  CONTACT = 'Contacts',
  EVENT = 'Event',
  ACCESS_PRIVILEGE = 'Access Privilege',
  MOBILE_APP_SYNC = 'Mobile Selection',
  SCREENING_ROOM = 'Screening Room',
  ORGANIZATION = 'Organization',
  ACCESS_GROUP = 'Access Group',
  UNDEFINED = '-',
}

export type IActionName =
  | IContactActionName
  | IConferenceActionName
  | IAssetActionName
  | IProductActionName
  | ISelectionActionName
  | IOrganizationActionName
  | IShowcaseActionName
  | IMarketingEventActionName
  | IBasketActionName
  | IAccessPrivilegeActionName
  | IMobileAppSyncActionName
  | IGroupActionName
  | ICommonActionName
  | ISubtitleActionName
  | ILocationsActionName
  | IMeetingActionName
  | IWorkflowActionName
  | ICastCrewActionName
  | IListItemActionName
  | INewsActionName
  | IRecommendationActionName
  | IDeliveryActionName
  | IScreeningRoomActionName
  | IProducerPortalActionName
  | IConnectionActionName
  | IAudioActionName
  | IAccessGroupActionName;

export enum Pages {
  LIST,
  DETAILS,
  SELECTION_LIST,
  SELECTION_DETAILS,
  PRODUCT_ASSETS_TAB,
  PRODUCT_RELATED_TAB,
  PRODUCT_CHILDREN_TAB,
  PRODUCT_CAST_CREW_TAB,
  ASSET_PRODUCTS_TAB,
  ASSET_SUBTITLES_TAB,
  EVENT_PRODUCTS_TAB,
  EVENT_ASSETS_TAB,
  EVENT_SUB_EVENTS_TAB,
  ORGANIZATION_CONTACTS_TAB,
  RECOMMENDATIONS_LIST,
  RECOMMENDATIONS_DETAILS,
  ACCESS_PRIVILEGES_LIST,
  ACCESS_PRIVILEGES_DETAILS,
  ACCESS_PRIVILEGES_PRODUCTS_TAB,
  MOBILE_APP_SYNC_PRODUCTS_TAB,
  BASKETS,
  BASKET_DETAILS,
  CONTACT_BASKETS,
  CONTACT_BASKET_DETAILS,
  CONFERENCE,
  LOCATIONS,
  COLLECTION_ITEMS,
  SCREENING_ROOM_DETAILS,
  DELIVERIES,
  DELIVERIES_DETAILS,
  ORGANIZATION_DELIVERY_TAB,
  ORGANIZATION_DELIVERY_DETAILS,
  ORGANIZATION_DELIVERY_INFORMATION,
  CAST_CREW_PRODUCTS_TAB,
  SELECTION_ENTITY_TAB,
  ACCESS_GROUPS_LIST,
  ACCESS_GROUP_DETAILS,
  ACCESS_GROUP_DETAILS_PRODUCT_TAB,
  MOBILE_APP_SYNC_ASSETS_TAB,
}

export type IUseAction<T, D extends IActionName> = (
  entities: T[],
  options?: IUseActionsOptionParam<T>,
) => IActionConfig<T, D>;

export type IShouldDisplayActionFunction<T, D extends IActionName> = (
  action: IAction<D>,
  index: number,
  user: IContact | null,
  items: T[],
  options: IUseActionsOptionParam,
) => boolean;

export interface IActionConfig<T, D extends IActionName> extends IAction<D> {
  shouldDisplay?: IShouldDisplayActionFunction<T, D>;
}

export interface IAction<D extends IActionName> {
  name: D | 'divider' | ITitleDividerActionName;
  title?: string;
  handler?: (e: React.MouseEvent<HTMLElement>) => void;
  icon?: IconName | JSX.Element;
  intent?: Intent;
  isTitle?: boolean;
  isDisabled?: boolean;
  requiredPowers?: string[];
}

export type IUseActionsContext = 'single' | 'multi';

export interface IUseActionsOptionParam<T = unknown> {
  onSuccess?: (action?: IActionName, entity?: T, updatedItems?: unknown) => Promise<void> | void;
  onFailure?: () => Promise<void>;
  context: IUseActionsContext;
  page?: Pages;
  hasRestorableArchive?: boolean;
  hasArchive?: boolean;
  productId?: ItemId;
  groupName?: ITitleDividerActionName;
  groupParamName?: string;
  groupId?: ItemId;
  hideAccessCheckboxes?: boolean;
  calendar?: React.RefObject<FullCalendar>;
  closePrevDialog?: boolean;
  tab?: T extends McGenericRecommendationProduct | DeliveriesGenericDeliveryPackage ? DetailsPageTabs : never;
  section?: T extends UmGenericListCollection ? IWebsitePageValues : never;
  messageSuccess?: string;
  product?: IProduct;
  parentId?: T extends IMm3Asset ? ItemId : never;
  conference?: IConference;
  withParentField?: T extends IMm3Asset ? boolean : never;
  isAssetRecommendation?: boolean;
  assetId?: T extends IAudioTrack ? ItemId : T extends ISubtitle ? ItemId : never;
}

export interface ICreateSelectionParams {
  name: string;
  access_level: string;
  main_entity_type: string;
}

export type IUseActionsResponse<D extends IActionName> = IAction<D>[];

export type ISelectionEntityType = 'products' | 'assets' | 'users';

export interface IPassOnDataFields {
  fields: string[];
}

export interface IRecipient {
  recipient_type: 'email' | 'user';
  recipient_id: string;
}
export type ICreateWorkFlowPayload<
  T extends Omit<McGenericRecommendationProduct, 'asset_ids'> | McGenericRecommendationAsset = Omit<
    McGenericRecommendationProduct,
    'asset_ids'
  >,
> = {
  include_descendants?: boolean;
  descendants_ids?: number[];
  asset_ids: ItemId[];
  recipients: string[];
  cc_recipients?: string[];
  bcc_recipients?: string[];
  contact_selections: ISelection[];
  cc_contact_selections: ISelection[];
  bcc_contact_selections: ISelection[];
  assetList: string[];
  asset_selections: ISelection[];
  productList: string[];
  product_selections: ISelection[];
  includeFutureEpisodes: boolean;
  send_emails?: boolean;
} & T;

export type IAssetArtifact = {
  id: IItemId;
  type: string;
  asset_id: IItemId;
  storage_url: string;
  file_size: number;
  overwritten_artefact_name?: string | null;
  checked?: boolean;
};

export interface IAssetWithArtifact extends IAsset {
  artefacts: {
    id: IItemId;
    type: string;
    asset_id: IItemId;
    storage_url: string;
    overwritten_artefact_name?: string | null;
  }[];
}

export type ICreateWorkFlowDeliveryPayload<T extends DeliveriesGenericDeliveryPackage> = {
  organization_id: number;
  recipients?: ItemId[];
  connection_id: ItemId;
  product_ids?: number[];
  product_ancestry?: string;
  asset_ids: ItemId[];
  artefacts?: IAssetArtifact[];
  include_descendants?: boolean;
  connections?: DeliveriesGenericConnection[];
  parent_child?: {
    id: ItemId;
    parent_id: ItemId;
  };
} & T;

export const connectionRegions = ['us-east-1', 'eu-west-1'];
export type IConnectionDeliveryPayload<T extends DeliveriesGenericConnection> = {
  username: string;
  password: string;
  port: number;
  host: string;
  organization_id: number | null;
  bucket: string;
  region: 'us-east-1' | 'eu-west-1';
  aws_access_key_id: string;
  aws_secret_access_key: string;
} & T;

export type IDeliveriesGenericConnectionWithStatus = DeliveriesGenericConnection & {
  status?: string;
};
